import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

const ExcelExport = ({ xlsxfilename, data, plaza, date }) => {

    const handleExport = async () => {
        try {
            // Step 1: Create a workbook and a worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report');

            // Step 2: Add a title row (completely isolated)
            const titleText = `${plaza} ${xlsxfilename} - (${moment(date).format('D MMMM YYYY')})`;
            const titleRow = worksheet.addRow([titleText]);

            // Apply styles to the title row
            titleRow.getCell(1).font = { size: 12, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            // Merge the title across all columns
            worksheet.mergeCells('A1:I1'); // Adjust range according to the number of columns

            // Step 3: Add a blank row for visual spacing (optional but useful for isolation)
            worksheet.addRow([]);

            // Step 4: Add static column headers
            const headerRow = worksheet.addRow([
                'FlatNo', 'Total Unit', 'Prev Unit', 'Units', 
                'Amount', 'Motor Bill', 'Total Amount', 'Paid', 'Paid Date', 'Remaining'
            ]);
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, size: 12 };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
            });

            // Step 5: Add data rows
            data.forEach((item) => {
                const rowData = [
                    item.name || '',
                    item.total_unit || '',
                    item.previous_unit || '',
                    item.rate_per_unit || '',
                    item.unit_amount || '',
                    item.rate_per_unit || '',  // Adjust as necessary
                    item.total_amount || '',
                    item.paid_amount || '',
                    item.paid_date || '',
                    item.remaining_amount || ''
                ];
                worksheet.addRow(rowData).eachCell((cell) => {
                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                });
            });

            // Step 6: Adjust columns width
            worksheet.columns = [
                { width: 10 }, { width: 10 }, { width: 10 }, { width: 5 },
                { width: 10 }, { width: 10 }, { width: 15 }, { width: 5 }, { width: 10 },{ width: 15 }
            ];

            // Step 7: Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), `${xlsxfilename}.xlsx`);
        } catch (error) {
            console.error('Error generating Excel file:', error);
        }
    };

    return (
        <button className="btn btn-info" onClick={handleExport}>
            Export to Excel
        </button>
    );
};

export default ExcelExport;
