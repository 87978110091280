
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import swal from 'sweetalert';
import { createUnitReport, newMotorReport  } from '../../services/apiServices';

class DateModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
         this.resetValue();
         this.props.parentCallback('cancel'); 
    }
    resetValue = () => {
        this.setState({date:''})
    } 
  
    
    handleSubmit = async () => {
        console.log(this.state.date)
        const data = {
            date: this.state.date,
            pid: this.props.pid
        }
       let result = await createUnitReport({data});
        console.log('unit-report', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            this.resetValue();
            this.props.parentCallback('created');
        }
    }
    
  render(){ 
    const currentDate = new Date();

    // Calculate the date for two months ago
    const twoMonthsAgo = new Date(currentDate);
    twoMonthsAgo.setMonth(currentDate.getMonth() - 4);

    // Get dates in the format YYYY-MM-DD
    const minDate = twoMonthsAgo.toISOString().split('T')[0];
    const maxDate = new Date(currentDate.getFullYear(), 11, 31).toISOString().split('T')[0]; // End of the current year

      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}>
                <h5 className="text-center col-11 acc-label mb-0">New Report for {this.props.type}</h5>
                </Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row p-2">
            
     
                <div className="form-group col-lg-12">
                <label className="acc-label">Unit Rate</label>
                <input type='date' value={this.state.date} className="form-control w-lg-90" 
                onChange={(e) => this.setState({ date: e.target.value })}
                min={minDate} // Set the minimum date to two months ago
                max={maxDate} // Set the maximum date to the end of the current year
                />
                </div>

                </div>
                </form>
                </Modal.Body>  
                <Modal.Footer>  
                {this.state.date? 
                <Button className="btn btn-success w-100" onClick={this.handleSubmit}>Create Unit Report</Button>  :''}
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default DateModel