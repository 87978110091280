
export const Plaza = [
    {
        title: 'Male',
        value: '0'
    },
    {
        title: 'Female',
        value: '1'
    }
];

const urduMonths = [
    'جنوری', 'فروری', 'مارچ', 'اپریل', 'مئی', 'جون',
    'جولائی', 'اگست', 'ستمبر', 'اکتوبر', 'نومبر', 'دسمبر'
  ];
  export const getNextMonthInUrdu = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const nextMonth = (currentMonth + 1) % 12; // Get the next month (handles December -> January)
    return urduMonths[nextMonth];
  };
  
  // Function to generate the Urdu note with the next month
  export const generateDynamicUrduNote = () => {
    const nextMonthInUrdu = getNextMonthInUrdu();
    const day = 2; // Static day
    const year = 2024; // Static year
    return `نوٹ: آخری تاریخ ${day} ${nextMonthInUrdu} ${year}`;
  };