import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './components/Login/login';
import { isTokenValid } from './utilis/auth';
import Header from './components/Layout/header';
import PrivateRoute from './components/PrivateRoute';
import UserForm from './components/Users/UserForm';
import Dashboard from './components/Dashboard/dashboard';
import Logout from './components/Login/logout';
import Setting from './components/settings';
import Footer from './components/Layout/footer';
import ViewFlats from './components/Plazas/viewFlats';
import AddFlat from './components/Plazas/addFlats';
import EditFlat from './components/Plazas/editFlats';
import FlatStatus from './components/Plazas/flatStatus';
import UnitReport from './components/Plazas/unitReport';
import MonthlyReport from './components/Plazas/monthlyReport';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const shouldHideSidebar = location.pathname === '/login' || location.pathname === '/'
  || location.pathname === '/create-user';

  useEffect(() => {
    // const checkToken = async () => {
    //   const valid = await isTokenValid();
    //   if (!valid) {
    //     localStorage.removeItem('token');
    //     navigate('/login');
    //   } else {
    //     setIsLoggedIn(true);
    //   }
    // };

    // checkToken();
  }, [navigate]);

  return (
    <div className="app-container">
    {!shouldHideSidebar && <Header />}
    <main className="flex-grow-1">
    <Routes>
      <Route path="/login" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/create-user" element={<UserForm />} />
      <Route path="/dashboard" element={<PrivateRoute isLoggedIn={isLoggedIn}><Dashboard /></PrivateRoute>} />
      <Route path="/view-flats/:pid" element={<PrivateRoute isLoggedIn={isLoggedIn}><ViewFlats /></PrivateRoute>} />
      <Route path="/add-flat/:pid" element={<PrivateRoute isLoggedIn={isLoggedIn}><AddFlat /></PrivateRoute>} />
      <Route path="/setting" element={<PrivateRoute isLoggedIn={isLoggedIn}><Setting /></PrivateRoute>} />
      <Route path="/update-flat/:flatid" element={<PrivateRoute isLoggedIn={isLoggedIn}><EditFlat /></PrivateRoute>} />
      <Route path="/flat-status/:flatid" element={<PrivateRoute isLoggedIn={isLoggedIn}><FlatStatus /></PrivateRoute>} />
      <Route path="/view-unit-report" element={<PrivateRoute isLoggedIn={isLoggedIn}><UnitReport /></PrivateRoute>} />
      <Route path="/view-monthly-report" element={<PrivateRoute isLoggedIn={isLoggedIn}><MonthlyReport /></PrivateRoute>} />
    </Routes>
    </main>
    {!shouldHideSidebar && <Footer />} {/* Add the Footer here */}
  </div>
  );
}

export default App;
